import { createContext, useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const AnalyticsContext = createContext();

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error('useAnalytics must be used within an AnalyticsProvider');
  }
  return context;
};

export const AnalyticsProvider = ({ children }) => {
    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
    const analyticsUrl = `${baseUrl}/analytics`;
    const analyticsEventUrl = `${baseUrl}/analyticsevent`;

    const visitorId = localStorage.getItem('visitorId') || uuidv4();
    const sessionId = uuidv4();
    const location = useLocation();
    const lastPageEnterTime = useRef(Date.now());
    const lastPath = useRef(location.pathname);

    const createAnalyticsPayload = (path, timeOnPage, status = "arriving") => {
        const payload = {
            visitorId,
            sessionId,
            path,
            referrer: document.referrer || null,
            userAgent: navigator.userAgent,
            screenResolution: `${window.screen.width}x${window.screen.height}`,
            language: navigator.language,
            timeOnPage: Math.max(0, Math.floor(timeOnPage)),
            pagestatus: status,
            timestamp: Date.now()
        };

        return Object.fromEntries(
            Object.entries(payload).filter(([_, value]) => value != null)
        );
    };

    const logPageView = async (path, timeOnPage = 0) => {
        try {
            const payload = createAnalyticsPayload(path, timeOnPage);
            
            if (!payload.visitorId || !payload.path || payload.timeOnPage < 0) {
                console.warn('Invalid analytics payload:', payload);
                return;
            }

            //console.log("the path is", path)

            // Use AbortController to handle timeout
            const controller = new AbortController();
            const timeoutId = setTimeout(() => controller.abort(), 5000);  // 5 second timeout

            await fetch(analyticsUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
                signal: controller.signal,
                keepalive: true  // Ensures request completes even if page is unloading
            });

            clearTimeout(timeoutId);
        } catch (error) {
            if (error.name === 'AbortError') {
                console.warn('Analytics request timed out');
            } else {
                console.error('Failed to log page view:', error);
            }
        }
    };

    useEffect(() => {
        localStorage.setItem('visitorId', visitorId);
    }, [visitorId]);

    useEffect(() => {
        const currentPath = location.pathname;
        
        if (currentPath === lastPath.current && lastPath.current !== null) {
            return;
        }

        const timeOnPreviousPage = Date.now() - lastPageEnterTime.current;

        if (lastPath.current !== null) {
            logPageView(lastPath.current, timeOnPreviousPage);
        }

        logPageView(currentPath);

        lastPageEnterTime.current = Date.now();
        lastPath.current = currentPath;

        const handleBeforeUnload = () => {
            const finalTimeOnPage = Date.now() - lastPageEnterTime.current;
            const payload = createAnalyticsPayload(currentPath, finalTimeOnPage, "leaving");

            // Use sendBeacon for more reliable data transmission during page unload
            if (navigator.sendBeacon) {
                const blob = new Blob([JSON.stringify(payload)], { type: 'application/json' });
                navigator.sendBeacon(analyticsUrl, blob);
            } else {
                // Fallback to fetch with keepalive
                fetch(analyticsUrl, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(payload),
                    keepalive: true
                }).catch(console.error);
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [location.pathname]);

    const value = {
        visitorId,
        sessionId,
        logPageView
    };

    return (
        <AnalyticsContext.Provider value={value}>
            {children}
        </AnalyticsContext.Provider>
    );
};