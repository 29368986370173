import React from 'react';
import ReactDom from 'react-dom/client';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';
//import { AnalyticsProvider } from './context/AnalyticsProvider';
//import { AuthContextProvider } from './context/AuthContext';


import App from './App';

const store = createStore(reducers, compose(applyMiddleware(thunk)))

const container =  document.getElementById('root');
const root = ReactDom.createRoot(container);

root.render(
    
        <Provider store={store}>
            <App />
        </Provider> 
    
);


